import React, { useState } from "react";
import { Modal, Carousel } from "react-bootstrap";

export default function ModalGaleria ({show, handleClose, datos, galeriaPrincipal}) {
    //console.log("ModalGaleria: ", galeriaPrincipal);

    const [ accessoryIndex, setAccessoryIndex ] = useState(0);

    /*const handleSelectAccessory = (index) => {
        setAccessoryIndex(index);
    };*/

    return(
        <>
          <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {datos?.nombre === "foto" ? (
                        <Carousel >
                        {datos.datos?.map((obj, i) => {
                             return(
                                 <Carousel.Item key={i}>
                                     <img src={obj} width="100%" />
                                 </Carousel.Item>
                             )
                         })}
                     </Carousel>

                    ): (
                        <Carousel controls={false} indicators={false}>
                            <Carousel.Item >
                                <video width='100%' controls>
                                    <source src={datos.datos} type="video/mp4"/>
                                </video>
                            </Carousel.Item>
                        </Carousel>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )

}

                   {/*<Carousel activeIndex={galeriaPrincipal?.index} onSelect={() => {setAccessoryIndex(galeriaPrincipal?.index)}}>
                           {datos.datos?.map((obj, i) => {
                                return(
                                    <Carousel.Item key={i}>
                                        <img src={obj} width="100%" />
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>*/}