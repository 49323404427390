import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import GatsbyImage from "gatsby-image";
import { globalHistory } from "@reach/router";

import SEO from "../../components/seo";
import api from "../../actions/riveroapi";
import { NumericFormat } from "react-number-format";
import Layout from "../../components/layouts/layout";
import ModalGaleria from "../../components/widgets/modalGaleria";
import { Separador } from "../../components/widgets/miscelaneos";
import TabsVersionesLujo from "../../components/widgets/TabsVersionesLujo";
import { SeccionColoresLujo, ShimmerCarImagenLujos, ShimmerCarTextosLujo } from "../../components/widgets/miscelaneos";

import FormularioSalesforce from "../../components/widgets/formularios/FormularioSalesforce";
import FormularioNissan from "../../components/widgets/formularios/FormularioVentaNissan";
import iconVideo from "../../images/icons/btn_video.png";

export default function AutosLandingLujo(props){
    /* const parameters = props; */
    const parameters = props.location.search;
    //console.log("AYUDA esto es un GLOBAL en autosLanding Lujo:",globalHistory.location.pathname.split("/"));

    const path_auto = props.location.pathname
    var auto = path_auto.split("-");
    var make = auto[0].replace(/[^a-zA-Z0-9 ]/g, " ");
    var marca = make.split(" ")

    /* let arrayParams = paramsPublicidad.split(/[?&]+/); */
    /* console.log("Tengo props: ", props); */

    const [ car, setCar ] = useState();
    const [ selectColor, setSelectColor ] = useState({image: "", nombre: ""});
    const [ galeriaPrincipal, setGaleriaPrincipal ] = useState({imagen: "", index: 0});
    const [ pagoMensualInicio, setPagoMensualInicio ] = useState();
    const [ cargandoCar, setCargandoCar ] = useState(false);
    const [ datosGallery, setDatosGallery]  = useState({nombre: "", datos: ""})


    const paramsPublicidad = typeof window !== 'undefined' ? localStorage.getItem('paramsPublicidad') : null;

    function onPageReloadNavigate(slug) {
        if (paramsPublicidad) {
          navigate(`/${marca[1]}/${slug}?${paramsPublicidad}`);
        } else {
          navigate(`/${marca[1]}/${slug}`);
        }
      
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      }
      

    const [ showModalSale, setShowModalSale ] = useState(false);
    const [ show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [ showColors, setShowColors] = useState(false);
    const [ versionCarro, setVersionCarro ] = useState();

    useEffect(() => {
        var limpiaSlug = props.slug.split("&");
        setCargandoCar(true)
        api.getCar(limpiaSlug[0] ? limpiaSlug[0] : limpiaSlug).then((response) => {
             console.log("response: ", response);
            setCar(response);
            setVersionCarro(response?.versions[0].name)
            setCargandoCar(false)
            setGaleriaPrincipal({
                ...galeriaPrincipal,
                imagen: response.gallery[0],
                index: 0
            });
            let obj = {
                car_id: response.versions[0].id,
                entry_percentage: 25,
                months: 60,
                warraty_id: 1,
            }
            setSelectColor({
                ...selectColor,
                image: response.colors[0].image,
                nombre: response.colors[0].name
            }) 

            api.createQuotation(obj).then((r) => {
                setPagoMensualInicio(r.data.payment.monthly_payment);
            }).catch((error) => console.log("Hay un error en el calculador de mensualidad: ", error));
        });
    },[]);


    function onSelectImage (image, index){
        setGaleriaPrincipal({
            ...galeriaPrincipal,
            imagen: image,
            index: index
        });
    }

    function onCambioColor(color){
        /* console.log("ColorSeleccionado: ", color); */
        setSelectColor({
            ...selectColor,
            image: color.image,
            nombre: color.name
        })
    }

    function onPageReloadNavigate(slug){
        //console.log(paramsPublicidad) 
        navigate(`/${marca[1]}/${slug}${paramsPublicidad ? `&${paramsPublicidad}` : ``}`);

        setTimeout(() => {
            window.location.reload(false);
        }, 500) 
    }

    function onShowGallery(data){
        setShow(true);
        if(data === "foto"){
            setDatosGallery({
                ...datosGallery,
                nombre: "foto", 
                datos: car?.gallery
            })
        } else {
            setDatosGallery({
                ...datosGallery,
                nombre: "video", 
                datos: car?.videos
            })
        }
    }
    

    return(
        <Layout parameters={parameters}>
            <SEO
                title={`${
                    car?.make.toLowerCase() ? car?.make.toLowerCase()  + ` ` + car?.model.toLowerCase()  + ` ` + car?.year  : ""
                } | Nissan Rivero - Nissan Agencia Monterrey`}
                description={`${
                    car?.model.toLowerCase() ? car?.model.toLowerCase() : ""
                } - Nissan Rivero, Agencia Nissan Monterrey March, Versa, Altima, Sentra, Kicks - venta autos nuevos y seminuevos - Nissan Las Torres, Nissan Contry, Nissan Valle`}
                keywords={`${
                    car?.make ? car?.make + ` ` + car?.model + ` ` + car?.year : ""
                },  Nissan Rivero, Agencia Nissan Monterrey,agencia nissan, autos seminuevos, carro nuevo, carro nissan`}
                make={car?.make ? car?.make : ""}
                model={car?.model ? car?.model : ""}
                year={car?.year ? car?.year : ""}
                image={car ? car.image : ""}
                precio={car?.price ? car?.price : "180000"}
                sku={car ? car.id : ""}
                offer={car ? car.id : ""}
                details={car?.name ? car?.name : ""}
                path={`nuevos/lujo/${car ? car.slug : ""}`}
            />
            <Container className="backgroundClaro">
                <Row className="pt-2 pb-2">
                    <Col style={{display: 'flex', color: 'white'}}>
                        <Link to={"/nissan/catalogo/nuevos/" + paramsPublicidad} style={{color:'#d40028'}}>Regresar</Link>
                        <div className="px-2">/</div>
                        <p className="mb-0" style={{display: 'flex', alignItems: 'center'}}>{car?.model} {car?.year}</p>
                    </Col>
                </Row>
                <Row className="pb-4">
                        <div className="d-md-block d-lg-none">
                            <Row className="backgroundNegro p-2 text-white">
                                <div style={{width: '70%',display: 'flex' , alignItems: 'center'}}>
                                    {cargandoCar === true ? (
                                        <ShimmerCarTextosLujo width={'100%'} height={40} color="rgba(255, 255, 255, 0.15)"/>
                                    ) : 
                                    <h2 className="mb-0">
                                        { car?.model + " " + car?.year}
                                    </h2> }
                                </div>
                                <div style={{width: '30%'}}>
                                    {cargandoCar === true ? (
                                        <ShimmerCarTextosLujo width={'100%'} height={40} color="rgba(255, 255, 255, 0.15)"/>
                                    ) : 
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                        {car && car.years.length > 0 ? (
                                            car.years?.map((obj, i) => {
                                                const btnClass = obj.year === car?.year ? "botonLujo-anios" : "botonLujo-anios-inactivo";
                                                return(
                                                    <div 
                                                        key={i}
                                                        onClick={() => onPageReloadNavigate(obj.slug)}
                                                        style={{
                                                            paddingLeft: 16,
                                                            paddingRight: 16,
                                                            paddingTop: 8,
                                                            paddingBottom: 8,
                                                            margin: 5,
                                                            cursor: 'pointer'
                                                        }}
                                                        className={btnClass}
                                                        aria-current="date"
                                                    >
                                                        {obj.year}
                                                    </div>
                                                )
                                            })
                                        ) : (<div></div>)}
                                    </div> }
                                </div>
                            </Row>
                        </div>
                    <Col lg={7} md={12} sm={12}>
                        {
                            cargandoCar === true ? (
                                <ShimmerCarImagenLujos/>
                            ) : 
                            <div 
                                onClick={() => onShowGallery("foto")}
                                style={{ backgroundImage: "url(" + galeriaPrincipal.imagen + ")" }}
                                className="ImagenFullPrincipal">
                            </div>
                        }
                        <div className="backgroundOscuro">
                            <div className="rounded-bottom  containerItemsGaleria">
                                { car && car.gallery.length > 0 ? (
                                    car.gallery.map((obj, i) => {
                                        return(
                                            <div className="p-1 img-fluid" key={i}>
                                                <img
                                                    className="itemGalerias"
                                                    src={obj}
                                                    alt={ car.make + " " + car.model + " " + car.year + " galeria " + i }
                                                    data-target="#carouselExample"
                                                    data-slide-to="0"
                                                    loading="lazy"
                                                    onClick={() => onSelectImage(obj, i)}
                                                />
                                            </div>
                                        )
                                    })
                                ) : <div>Sin imágenes en galeria.</div> }
                            </div>
                            <Row className="p-4 mb-3 mt-3">
                                {car && car.videos.length > 0 ? (
                                    <Col onClick={() => onShowGallery("video")} >
                                        <center>
                                            <div
                                            className="boton-videosLujo"
                                            id="btnVideo"
                                            >
                                            <img src={iconVideo} height="35px" />
                                            <span className="mx-2">Video</span>
                                            </div>
                                        </center>
                                    </Col>
                                ) : null }
                            </Row>
                        </div>
                    </Col>
                    <Col lg={5} md={12} sm={12}>
                        <div className="d-lg-block d-none">
                            <Row className="backgroundNegro p-2 text-white">
                                <Col lg={7} style={{display: 'flex', alignItems: 'center'}}>
                                    {cargandoCar === true ? (
                                        <ShimmerCarTextosLujo width={'100%'} height={40} color="rgba(255, 255, 255, 0.15)"/>
                                    ) : 
                                    <h1 className="mb-0">
                                        { car?.model + " " + car?.year}
                                    </h1> }
                                </Col>
                                <Col lg={5}>
                                    {cargandoCar === true ? (
                                        <ShimmerCarTextosLujo width={'100%'} height={40} color="rgba(255, 255, 255, 0.15)"/>
                                    ) : 
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                        {car && car.years.length > 0 ? (
                                            car.years?.map((obj, i) => {
                                                const btnClass = obj.year === car?.year ? "botonLujo-anios" : "botonLujo-anios-inactivo";
                                                return(
                                                    <div 
                                                        key={i}
                                                        onClick={() => onPageReloadNavigate(obj.slug)}
                                                        style={{
                                                            paddingLeft: 16,
                                                            paddingRight: 16,
                                                            paddingTop: 8,
                                                            paddingBottom: 8,
                                                            margin: 5,
                                                            cursor: 'pointer'
                                                        }}
                                                        className={btnClass}
                                                        aria-current="date"
                                                    >
                                                        {obj.year}
                                                    </div>
                                                )
                                            })
                                        ) : (<div></div>)}
                                    </div> }
                                </Col>
                            </Row>
                        </div>
                        <Row className="bg-white p-2 pt-3 pb-3">
                            {car?.make == "NISSAN" ? (
                                <>
                                <Col lg={12} md={12} sm={12}>
                                    <h1 className="mb-0" style={{color: '#d40028', fontSize: '3.5vh', fontWeight: '700', textAlign: 'center'}}>$<NumericFormat displayType="text" value={car.price} allowLeadingZeros thousandSeparator="," className="px-2"/> <span style={{fontSize: '2vh'}}>MXN</span> </h1> 
                                </Col>
                                </>
                            ):(
                                <>
                                <Col lg={4} md={4} sm={4} style={{color: '#909090', fontSize: '1.8vh', width: '40%'}}>
                                    <strong>25%</strong> Enganche <br/>
                                    <strong>60</strong> meses desde:
                                </Col>
                                <Col lg={8} md={8} sm={8} style={{width: '60%'}}>
                                    {cargandoCar === true ? (
                                        <ShimmerCarTextosLujo width={'100%'} height={40} color="rgba(0, 0, 0, 0.15)"/>
                                    ): 
                                    <div style={{display: 'flex', alignItems: 'flex-end', color: '#2486fc', justifyContent: 'center'}}>
                                        <h1 className="mb-0" style={{fontSize: '3.5vh', fontWeight: '700'}}>$<NumericFormat displayType="text" value={pagoMensualInicio} allowLeadingZeros thousandSeparator="," className="px-2"/> </h1> MXN
                                    </div>}
                                </Col>
                                </>
                                )}

                        </Row>
                        <Row className="backgroundOscuro pt-1">
                            <div>
                                <p style={{fontSize: 10, color: '#656d78'}}>
                                    *Planes sujetos a cambio sin previo aviso, crédito sujeto a aprobación por financiera. no incluye (Placas | Seguros), planes SIN anualidades. Aplican restriciones.
                                </p>
                            </div>
                            <div className="px-2 pb-2">
                                <Button className="btn-rojo-LandingLujos" onClick={() => setShowModalSale(true)}>
                                    Contactar Asesor
                                </Button>
                            </div>
                        </Row>
                        <SeccionColoresLujo car={car} onCambioColor={onCambioColor} selectColor={selectColor} noModal={false} setShowColors={setShowColors}/>
                    </Col>
                </Row>
                <Row className="backgroundOscuro p-4">
                    <div className="mt-4 pb-4 text-white">
                        <center><h3>Versiones</h3></center>
                        <center><Separador/></center>
                    </div>
                    <TabsVersionesLujo 
                        car={car ? car : []}
                        versions={car ? car.versions : []}
                        marca={car ? car.make : null}
                        modelo={car ? car.model : null}
                        year={car ? car.year : null}
                        setShowModalSale={setShowModalSale}
                    />
                </Row>
            </Container>
            <ModalGaleria show={show} handleClose={handleClose} datos={datosGallery} galeriaPrincipal={galeriaPrincipal}/>

            <Modal size="xl" show={showColors} onHide={() => setShowColors(false)}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <SeccionColoresLujo car={car} onCambioColor={onCambioColor} selectColor={selectColor} noModal={true}/>
                </Modal.Body>
            </Modal>
            <ModalSalesforce show={showModalSale} onHide={() => setShowModalSale(false)} car={car} props={props} versionCarro={versionCarro}/>
        </Layout>
    )
}


function ModalSalesforce({props, show, onHide, car, versionCarro, valorPorcentaje, mesValor, pagoMensual, versionSelect}){

    const [ versionSeleccionada, setVersionSeleccionada ] = useState();

    let splitVersion = versionSeleccionada?.split(/-/, 2);

    let objectCar = {
        make: car?.make,
        model: car?.model,
        year: car?.year,
        fullauto: `${car?.make} ${car?.model} ${car?.year}`
    }

    return(
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                Contactar Asesor
            </Modal.Header>
            <Modal.Body>

                <FormularioSalesforce props={props} infoCar={objectCar} pagina="detalle-de-nuevos">

                    <label htmlFor="contactoReferencia">
                        Elige la versión de tu 
                    </label>
                    <select
                        className="form-control"
                        id="00NHp0000194HUr" 
                        name="00NHp0000194HUr"
                        aria-describedby="version"
        /*                 onChange={(e) => {setVersionSeleccionada(e.target.value)}}
                        value={versionSeleccionada} */
                        >
                        {car
                        ? car.versions.map((version) => {
                            return (
                                <option value={`${version.name} - ${version.price}`} key={version.id}>
                                {version.name} $ <NumericFormat displayType="text" value={version.price} allowLeadingZeros thousandSeparator="," />
                                </option>
                            );
                            })
                        : null}
                    </select>

                    <input hidden id="00NHp0000194HWY" name="00NHp0000194HWY" value="Nuevos"/>
                    {/*<input hidden id="00N8a00000FPBY3" name="00N8a00000FPBY3" value={car?.sf_id} />*/}

                </FormularioSalesforce>
                
            </Modal.Body>
        </Modal>
    )
}